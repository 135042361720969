import moment from 'moment';
import getImage from './MarkerSVGMixin';

export default {
    mixins: [ getImage ],
    methods: {
        getRotatedIcon (vehicleData, rotateDegrees, type, manufacturer) {
            let state = this.getMarkerState(vehicleData)
            let color = this.getColorByState(state);
            let showArrow = this.showArrowByState(state);

            let markerSvg = this.getImage(rotateDegrees, type, color, showArrow, manufacturer);

            return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(markerSvg)}`
        },
        showArrowByState(state){
            return state === 'work' || state === 'maneuver' || state === 'move';
        },
        getColorByState(val) {
            switch (val) {
                case 'work':
                    // green
                    return "#2CA02C";
                case 'idle':
                    // red
                    return "#D62728";
                case 'maneuver':
                    // orange
                    return "#FF7E0E";
                case 'move':
                    // blue
                    return "#1F76B4";
                case 'active':
                    // grey
                    return "#797979";
                case 'inactive':
                    // brown
                    return "#5E3F20";
                case 'unavailable':
                    // black
                    return "#000000";
                default:
                    // brown
                    return "#5E3F20";
            }
        },
        getMarkerState(val) {

            if (val.is_unavailable) {
                return 'unavailable';
            }

            let ts = val._005;

            if (this.isInSeconds(val._005)) {
                ts = val._005 * 1000;
            }

            if (!val || !ts || !moment(ts).isValid()) {
                return 'active';
            }

            if (moment().diff(moment(ts), 'seconds') <= 120) {

                if (!val._787) {
                    return 'work';
                }

                switch (val._787) {
                    case 1:
                        return 'work';
                    case 2:
                        return 'idle';
                    case 3:
                        return 'maneuver';
                    case 4:
                        return 'move';
                    case 5:
                        return 'inactive';
                    default:
                        return 'work';
                }

            } else if ((moment().diff(moment(ts), 'seconds') > 120) &&
                (moment().diff(moment(ts), 'hours') <= 24)) {

                if(val._787 === 5){
                    return 'inactive';
                }
                return 'active';

            } else {
                return 'active';
            }
        },

        /**
         * concatena o numero com string em branco pois o toString() é geralmente varias vezes mais lento
         * que a concatenação
         *
         * transforma o numero em string, 10 é o tamanho em segundos ex: 1636467198, em mili 1636467198000
         * */
        isInSeconds(val) {
            val = val + '';
            return val.length === 10;
        },

        getVehicleStatusCode(val) {

            let ts = val._005;

            if (this.isInSeconds(val._005)) {
                ts = val._005 * 1000;
            }

            if (!val || !ts || !moment(ts).isValid()) {
                return 'DISCONNECTED_24'
            }

            if (moment().diff(moment(ts), 'seconds') <= 120) {
                return 'WORKING';
            } else if ((moment().diff(moment(ts), 'seconds') > 120) &&
                (moment().diff(moment(ts), 'hours') <= 24)) {
                if(val._787 === 5){
                    return 'WORKING'
                }
                return 'DISCONNECTED'
            } else {
                return 'DISCONNECTED_24'
            }
        }
    },
}
;
